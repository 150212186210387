<template>
  <div data-layout="site/public" class="bg-forgd-bgd-200 min-h-screen font-normal text-forgd-primary-900 font-display min-w-[1440px]">
    <div class="mx-auto">
      <slot />
    </div>
  </div>
</template>

<style>
:root {
  --vis-font-family: 'DM Mono';
  --vis-axis-font-family: 'DM Mono';
  --vis-axis-tick-label-color: #AEAEA4;
  --vis-tooltip-background-color: #EDEDEA;
  --vis-tooltip-border-color: #C7C7BE;
  --vis-tooltip-text-color: #001221;
  --vis-tooltip-font-size: 12px;
  --vis-tooltip-shadow-color:0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.20);
  --vis-tooltip-padding: 4px 12px;
}
</style>
